'use strict';

angular.module('cpformplastApp.jobs')
    .factory('Salesman', function() {

    class Salesman {
        constructor({_id, name}) {
          this.id = _id;
          this.name = name;
        }
    }

    return Salesman;
});
